import services from "../axios/dbManag";

export const VisualizzaDettaglioMatricola = (matricola) => {
  return services.dbManag
    .post("/assistenza/bonifica/anagrafiche/unisci/duplicati", {
      matricola,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const BonificaAnagrafica = (
  matricola_da_mantenere,
  matricola_da_cancellare,
  rollback
) => {
  return services.dbManag
    .post("/assistenza/bonifica/anagrafiche", {
      matricola_da_mantenere,
      matricola_da_cancellare,
      rollback,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
