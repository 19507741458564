<template>
  <div class="card mt-5">
    <div class="table-responsive">
      <table class="table table-striped gy-3 gs-7 align-top">
        <thead>
          <tr
            class="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200"
            style="font-weight: 500 !important"
          >
            <th scope="col-2"></th>
            <th
              scope="col-5"
              class="text-hover-primary"
              style="font-weight: 500 !important"
            >
              Matricola da mantenere
            </th>
            <th
              scope="col-5"
              class="text-hover-primary"
              style="font-weight: 500 !important"
            >
              Matricola da eliminare
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              class="fs-6 text-gray-800 border-bonifica"
              style="font-weight: 500 !important"
            >
              Matricola
            </td>
            <td class="border-bonifica">{{ matricolaCorretta.matricola }}</td>
            <td class="border-bonifica">{{ matricolaErrata.matricola }}</td>
          </tr>
          <tr>
            <td
              class="fs-6 text-gray-800 border-bonifica"
              style="font-weight: 500 !important"
            >
              Cognome
            </td>
            <td class="border-bonifica">{{ matricolaCorretta.cognome }}</td>
            <td class="border-bonifica">{{ matricolaErrata.cognome }}</td>
          </tr>
          <tr>
            <td
              class="fs-6 text-gray-800 border-bonifica"
              style="font-weight: 500 !important"
            >
              Nome
            </td>
            <td class="border-bonifica">{{ matricolaCorretta.nome }}</td>
            <td class="border-bonifica">{{ matricolaErrata.nome }}</td>
          </tr>
          <tr>
            <td
              class="fs-6 text-gray-800 border-bonifica"
              style="font-weight: 500 !important"
            >
              Data di nascita
            </td>
            <td class="border-bonifica">
              {{ getFormatDate(matricolaCorretta.data_nascita) }}
            </td>
            <td class="border-bonifica">
              {{ getFormatDate(matricolaErrata.data_nascita) }}
            </td>
          </tr>
          <tr>
            <td
              class="fs-6 text-gray-800 border-bonifica"
              style="font-weight: 500 !important"
            >
              Codice fiscale
            </td>
            <td class="border-bonifica">
              {{ matricolaCorretta.cod_fisc }}
            </td>
            <td class="border-bonifica">
              {{ matricolaErrata.cod_fisc }}
            </td>
          </tr>
          <tr>
            <td
              class="fs-6 text-gray-800 border-bonifica"
              style="font-weight: 500 !important"
            >
              Comune di nascita
            </td>
            <td class="border-bonifica">
              {{ matricolaCorretta.comune_nascita }}
            </td>
            <td class="border-bonifica">
              {{ matricolaErrata.comune_nascita }}
            </td>
          </tr>
          <tr>
            <td
              class="fs-6 text-gray-800 border-bonifica"
              style="font-weight: 500 !important"
            >
              Indirizzo di residenza
            </td>
            <td class="border-bonifica">
              {{ matricolaCorretta.indirizzo_residenza }}
            </td>
            <td class="border-bonifica">
              {{ matricolaErrata.indirizzo_residenza }}
            </td>
          </tr>
          <tr>
            <td
              class="fs-6 text-gray-800 border-bonifica"
              style="font-weight: 500 !important"
            >
              Nome Utente
            </td>
            <td class="border-bonifica">{{ matricolaCorretta.nome_utente }}</td>
            <td class="border-bonifica">{{ matricolaErrata.nome_utente }}</td>
          </tr>
          <tr>
            <td
              class="fs-6 text-gray-800 border-bonifica"
              style="font-weight: 500 !important"
            >
              Mat. Tec.
            </td>
            <td class="border-bonifica">
              {{ matricolaCorretta.matricola_tec }}
            </td>
            <td class="border-bonifica">{{ matricolaErrata.matricola_tec }}</td>
          </tr>
          <tr>
            <td
              class="fs-6 text-gray-800 border-bonifica"
              style="font-weight: 500 !important"
            >
              Mat. Udg.
            </td>
            <td class="border-bonifica">
              {{ matricolaCorretta.matricola_udg }}
            </td>
            <td class="border-bonifica">{{ matricolaErrata.matricola_udg }}</td>
          </tr>
          <tr>
            <td
              class="fs-6 text-gray-800 border-bonifica"
              style="font-weight: 500 !important"
            >
              Mat. Dir.
            </td>
            <td class="border-bonifica">
              {{ matricolaCorretta.matricola_dir }}
            </td>
            <td class="border-bonifica">{{ matricolaErrata.matricola_dir }}</td>
          </tr>
          <tr>
            <td
              class="fs-6 text-gray-800 border-bonifica"
              style="font-weight: 500 !important"
            >
              Tesseramenti
            </td>
            <td class="border-bonifica">
              <div
                class="card shadow bonifica"
                v-if="matricolaCorretta.tesseramenti"
              >
                <table class="table gy-3 gs-7 align-middle">
                  <thead>
                    <tr>
                      <th>Anno</th>
                      <th>Società</th>
                      <th>Tipo tessera</th>
                      <th>Disciplina</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(value, index) in matricolaCorretta.tesseramenti"
                      :key="index"
                    >
                      <td>{{ value.anno }}</td>
                      <td>{{ value.denominazione }}</td>
                      <td>{{ value.tipo_tessera }}</td>
                      <td>{{ value.disciplina }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
            <td class="border-bonifica">
              <div
                class="card shadow bonifica"
                v-if="matricolaErrata.tesseramenti"
              >
                <table class="table gy-3 gs-7 align-middle">
                  <thead>
                    <tr>
                      <th>Anno</th>
                      <th>Società</th>
                      <th>Tipo tessera</th>
                      <th>Disciplina</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(value, index) in matricolaErrata.tesseramenti"
                      :key="index"
                    >
                      <td>{{ value.anno }}</td>
                      <td>{{ value.denominazione }}</td>
                      <td>{{ value.tipo_tessera }}</td>
                      <td>{{ value.disciplina }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
          <!-- <tr>
            <td
              class="fs-6 text-gray-800 border-bonifica"
              style="font-weight: 500 !important"
            >
              Tess. Belardinelli
            </td>
            <td class="border-bonifica">
              {{ matricolaCorretta.matricola }}
            </td>
            <td class="border-bonifica">
              {{ matricolaErrata.matricola }}
            </td>
          </tr> -->
          <tr>
            <td
              class="fs-6 text-gray-800 border-bonifica"
              style="font-weight: 500 !important"
            >
              Consiglio
            </td>
            <td class="border-bonifica">
              <div
                class="card shadow bonifica"
                v-if="matricolaCorretta.consiglio"
              >
                <table class="table gy-3 gs-7 align-middle">
                  <thead>
                    <tr>
                      <th>Anno</th>
                      <th>Società</th>
                      <th>Qualifica</th>
                      <th>Data inizio</th>
                      <th>Data fine</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(value, index) in matricolaCorretta.consiglio"
                      :key="index"
                    >
                      <td>{{ value.anno }}</td>
                      <td>{{ value.denominazione }}</td>
                      <td>{{ value.qualifica }}</td>
                      <td>{{ getFormatDate(value.data_inizio) }}</td>
                      <td>{{ getFormatDate(value.data_fine) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
            <td class="border-bonifica">
              <div
                class="card shadow bonifica"
                v-if="matricolaErrata.consiglio"
              >
                <table class="table gy-3 gs-7 align-middle">
                  <thead>
                    <tr>
                      <th>Anno</th>
                      <th>Società</th>
                      <th>Qualifica</th>
                      <th>Data inizio</th>
                      <th>Data fine</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(value, index) in matricolaErrata.consiglio"
                      :key="index"
                    >
                      <td>{{ value.anno }}</td>
                      <td>{{ value.denominazione }}</td>
                      <td>{{ value.qualifica }}</td>
                      <td>{{ getFormatDate(value.data_inizio) }}</td>
                      <td>{{ getFormatDate(value.data_fine) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
          <!--  <tr>
            <td
              class="fs-6 text-gray-800 border-bonifica"
              style="font-weight: 500 !important"
            >
              Cons. Comitato
            </td>
            <td class="border-bonifica">{{ matricolaCorretta.matricola }}</td>
            <td class="border-bonifica">{{ matricolaErrata.matricola }}</td>
          </tr>
          <tr>
            <td
              class="fs-6 text-gray-800 border-bonifica"
              style="font-weight: 500 !important"
            >
              Res. Legale
            </td>
            <td class="border-bonifica">{{ matricolaCorretta.matricola }}</td>
            <td class="border-bonifica">{{ matricolaErrata.matricola }}</td>
          </tr>
          <tr>
            <td
              class="fs-6 text-gray-800 border-bonifica"
              style="font-weight: 500 !important"
            >
              Ref. Sportivo
            </td>
            <td class="border-bonifica">{{ matricolaCorretta.matricola }}</td>
            <td class="border-bonifica">{{ matricolaErrata.matricola }}</td>
          </tr>
          <tr>
            <td
              class="fs-6 text-gray-800 border-bonifica"
              style="font-weight: 500 !important"
            >
              Tecnico
            </td>
            <td class="border-bonifica">{{ matricolaCorretta.matricola }}</td>
            <td class="border-bonifica">{{ matricolaErrata.matricola }}</td>
          </tr> -->
          <tr>
            <td
              class="fs-6 text-gray-800 border-bonifica"
              style="font-weight: 500 !important"
            >
              Albo
            </td>
            <td class="border-bonifica">
              <span
                v-for="(value, index) in matricolaCorretta.iscrizione_albo"
                :key="index"
              >
                {{ value.anno }}
                <span
                  v-if="matricolaCorretta.iscrizione_albo.length > index + 1"
                  >-
                </span>
              </span>
            </td>
            <td class="border-bonifica">
              <span
                v-for="(value, index) in matricolaErrata.iscrizione_albo"
                :key="index"
              >
                {{ value.anno }}
                <span v-if="matricolaErrata.iscrizione_albo.length > index + 1"
                  >-
                </span>
              </span>
            </td>
          </tr>
          <tr>
            <td
              class="fs-6 text-gray-800 border-bonifica"
              style="font-weight: 500 !important"
            >
              Movimenti
            </td>
            <td class="border-bonifica">
              <div
                class="card shadow bonifica"
                v-if="matricolaCorretta.movimenti"
              >
                <table class="table gy-3 gs-7 align-middle">
                  <thead>
                    <tr>
                      <th>Anno</th>
                      <th>Data Movimento</th>
                      <th>Importo</th>
                      <th>Descrizione</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(value, index) in matricolaCorretta.movimenti"
                      :key="index"
                    >
                      <td>{{ value.anno }}</td>
                      <td>{{ getFormatDate(value.data_movimento) }}</td>
                      <td>{{ value.importo }}</td>
                      <td>{{ value.descrizione }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
            <td class="border-bonifica">
              <div
                class="card shadow bonifica"
                v-if="matricolaErrata.movimenti"
              >
                <table class="table gy-3 gs-7 align-middle">
                  <thead>
                    <tr>
                      <th>Anno</th>
                      <th>Data Movimento</th>
                      <th>Importo</th>
                      <th>Descrizione</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(value, index) in matricolaErrata.movimenti"
                      :key="index"
                    >
                      <td>{{ value.anno }}</td>
                      <td>{{ getFormatDate(value.data_movimento) }}</td>
                      <td>{{ value.importo }}</td>
                      <td>{{ value.descrizione }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
          <tr>
            <td
              class="fs-6 text-gray-800 border-bonifica"
              style="font-weight: 500 !important"
            >
              Documenti
            </td>
            <td class="border-bonifica">
              <div
                class="card shadow bonifica"
                v-if="matricolaCorretta.documenti"
              >
                <table class="table gy-3 gs-7 align-middle">
                  <thead>
                    <tr>
                      <th>Tipo</th>
                      <th>Oggetto</th>
                      <th>Descrizione</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(value, index) in matricolaCorretta.documenti"
                      :key="index"
                    >
                      <td>{{ value.tipo }}</td>
                      <td>{{ value.oggetto }}</td>
                      <td>{{ value.descrizione }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
            <td class="border-bonifica">
              <div
                class="card shadow bonifica"
                v-if="matricolaErrata.documenti"
              >
                <table class="table gy-3 gs-7 align-middle">
                  <thead>
                    <tr>
                      <th>Tipo</th>
                      <th>Oggetto</th>
                      <th>Descrizione</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(value, index) in matricolaErrata.documenti"
                      :key="index"
                    >
                      <td>{{ value.tipo }}</td>
                      <td>{{ value.oggetto }}</td>
                      <td>{{ value.Descrizione }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
          <tr>
            <td
              class="fs-6 text-gray-800 border-bonifica"
              style="font-weight: 500 !important"
            >
              Trasferimenti
            </td>
            <td class="border-bonifica">
              <div
                class="card shadow bonifica"
                v-if="matricolaCorretta.trasferimenti"
              >
                <table class="table gy-3 gs-7 align-middle">
                  <thead>
                    <tr>
                      <th>Anno prov.</th>
                      <th>Anno trasf</th>
                      <th>Societa prov.</th>
                      <th>Codice tessera</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(value, index) in matricolaCorretta.trasferimenti"
                      :key="index"
                    >
                      <td>{{ value.anno_prov }}</td>
                      <td>{{ value.anno_trasferimento }}</td>
                      <td>{{ value.societa_provenienza }}</td>
                      <td>{{ value.societa_provenienza }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
            <td class="border-bonifica">
              <div
                class="card shadow bonifica"
                v-if="matricolaErrata.trasferimenti"
              >
                <table class="table gy-3 gs-7 align-middle">
                  <thead>
                    <tr>
                      <th>Anno prov.</th>
                      <th>Anno trasf</th>
                      <th>Societa prov.</th>
                      <th>Codice tessera</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(value, index) in matricolaErrata.trasferimenti"
                      :key="index"
                    >
                      <td>{{ value.anno_prov }}</td>
                      <td>{{ value.anno_trasferimento }}</td>
                      <td>{{ value.societa_provenienza }}</td>
                      <td>{{ value.societa_provenienza }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
          <tr>
            <td
              class="fs-6 text-gray-800 border-bonifica"
              style="font-weight: 500 !important"
            >
              Classifiche
            </td>
            <td class="border-bonifica">
              <div
                class="card shadow bonifica"
                v-if="matricolaCorretta.classifiche"
              >
                <table class="table gy-3 gs-7 align-middle">
                  <thead>
                    <tr>
                      <th>Anno</th>
                      <th>Disciplina</th>
                      <th>Categoria</th>
                      <th>Gruppo</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(value, index) in matricolaCorretta.classifiche"
                      :key="index"
                    >
                      <td>{{ value.anno }}</td>
                      <td>{{ value.disciplina }}</td>
                      <td>{{ value.categoria_view }}</td>
                      <td>{{ value.gruppo }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
            <td class="border-bonifica">
              <div class="card shadow bonifica">
                <table
                  class="table gy-3 gs-7 align-middle"
                  v-if="matricolaErrata.classifiche"
                >
                  <thead>
                    <tr>
                      <th>Anno</th>
                      <th>Disciplina</th>
                      <th>Categoria</th>
                      <th>Gruppo</th>
                    </tr>
                  </thead>
                  <tr
                    v-for="(value, index) in matricolaErrata.classifiche"
                    :key="index"
                  >
                    <td>{{ value.anno }}</td>
                    <td>{{ value.disciplina }}</td>
                    <td>{{ value.categoria_view }}</td>
                    <td>{{ value.gruppo }}</td>
                  </tr>
                </table>
              </div>
            </td>
          </tr>
          <tr>
            <td
              class="fs-6 text-gray-800 border-bonifica"
              style="font-weight: 500 !important"
            >
              Stampa Tessere
            </td>
            <td class="border-bonifica">
              <div
                class="card shadow bonifica"
                v-if="matricolaCorretta.stampa_tessere"
              >
                <table class="table gy-3 gs-7 align-middle">
                  <thead>
                    <tr>
                      <th>Codice tessera</th>
                      <th>Data utlima stampa</th>
                      <th>Categoria</th>
                      <th>Gruppo</th>
                    </tr>
                  </thead>
                  <tr
                    v-for="(value, index) in matricolaCorretta.stampa_tessere"
                    :key="index"
                  >
                    <td>{{ value.cod_tessera }}</td>
                    <td>{{ getFormatDate(value.data_ultima_stampa) }}</td>
                  </tr>
                </table>
              </div>
            </td>
            <td class="border-bonifica">
              <div
                class="card shadow bonifica"
                v-if="matricolaErrata.stampa_tessere"
              >
                <table class="table gy-3 gs-7 align-middle">
                  <thead>
                    <tr>
                      <th>Codice tessera</th>
                      <th>Data utlima stampa</th>
                      <th>Categoria</th>
                      <th>Gruppo</th>
                    </tr>
                  </thead>
                  <tr
                    v-for="(value, index) in matricolaErrata.stampa_tessere"
                    :key="index"
                  >
                    <td>{{ value.cod_tessera }}</td>
                    <td>{{ getFormatDate(value.data_ultima_stampa) }}</td>
                  </tr>
                </table>
              </div>
            </td>
          </tr>
          <tr>
            <td
              class="fs-6 text-gray-800 border-bonifica"
              style="font-weight: 500 !important"
            >
              Corsi
            </td>
            <td class="border-bonifica">
              <div class="card shadow bonifica" v-if="matricolaCorretta.corsi">
                <table class="table gy-3 gs-7 align-middle">
                  <thead>
                    <tr>
                      <th>Corso</th>
                      <th>Stato</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(value, index) in matricolaCorretta.corsi"
                      :key="index"
                    >
                      <td>{{ value.corso }}</td>
                      <td>{{ value.stato }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
            <td class="border-bonifica">
              <div class="card shadow bonifica" v-if="matricolaErrata.corsi">
                <table class="table gy-3 gs-7 align-middle">
                  <thead>
                    <tr>
                      <th>Corso</th>
                      <th>Stato</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(value, index) in matricolaErrata.corsi"
                      :key="index"
                    >
                      <td>{{ value.corso }}</td>
                      <td>{{ value.stato }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
          <!-- <tr>
            <td
              class="fs-6 text-gray-800 border-bonifica"
              style="font-weight: 500 !important"
            >
              Iscritti Albo
            </td>
            <td class="border-bonifica">{{ matricolaCorretta.matricola }}</td>
            <td class="border-bonifica">{{ matricolaErrata.matricola }}</td>
          </tr>
          <tr>
            <td
              class="fs-6 text-gray-800 border-bonifica"
              style="font-weight: 500 !important"
            >
              Rel. Ins. Scuole
            </td>
            <td class="border-bonifica">{{ matricolaCorretta.matricola }}</td>
            <td class="border-bonifica">{{ matricolaErrata.matricola }}</td>
          </tr> -->
          <tr>
            <td
              class="fs-6 text-gray-800 border-bonifica"
              style="font-weight: 500 !important"
            >
              Resp. Scuole
            </td>
            <td class="border-bonifica">
              <div
                class="card shadow bonifica"
                v-if="matricolaCorretta.resp_scuole"
              >
                <table class="table gy-3 gs-7 align-middle">
                  <thead>
                    <tr>
                      <th>Società</th>
                      <th>Anno</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(value, index) in matricolaCorretta.resp_scuole"
                      :key="index"
                    >
                      <td>{{ value.denominazione }}</td>
                      <td>{{ value.anno }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
            <td class="border-bonifica">
              <div
                class="card shadow bonifica"
                v-if="matricolaErrata.resp_scuole"
              >
                <table class="table gy-3 gs-7 align-middle">
                  <thead>
                    <tr>
                      <th>Società</th>
                      <th>Anno</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(value, index) in matricolaErrata.resp_scuole"
                      :key="index"
                    >
                      <td>{{ value.denominazione }}</td>
                      <td>{{ value.anno }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
          <tr>
            <td
              class="fs-6 text-gray-800 border-bonifica"
              style="font-weight: 500 !important"
            >
              Tec. Scuole
            </td>
            <td class="border-bonifica">
              <div
                class="card shadow bonifica"
                v-if="matricolaCorretta.tec_scuole"
              >
                <table class="table gy-3 gs-7 align-middle">
                  <thead>
                    <tr>
                      <th>Società</th>
                      <th>Anno</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(value, index) in matricolaCorretta.tec_scuole"
                      :key="index"
                    >
                      <td>{{ value.denominazione }}</td>
                      <td>{{ value.anno }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
            <td class="border-bonifica">
              <div
                class="card shadow bonifica"
                v-if="matricolaErrata.tec_scuole"
              >
                <table class="table gy-3 gs-7 align-middle">
                  <thead>
                    <tr>
                      <th>Società</th>
                      <th>Anno</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(value, index) in matricolaErrata.tec_scuole"
                      :key="index"
                    >
                      <td>{{ value.denominazione }}</td>
                      <td>{{ value.anno }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
          <tr>
            <td
              class="fs-6 text-gray-800 border-bonifica"
              style="font-weight: 500 !important"
            >
              Prep. Fisico Scuole
            </td>
            <td class="border-bonifica">{{ matricolaCorretta.prep_fisico }}</td>
            <td class="border-bonifica">{{ matricolaErrata.prep_fisico }}</td>
          </tr>
          <tr>
            <td
              class="fs-6 text-gray-800 border-bonifica"
              style="font-weight: 500 !important"
            >
              Prep. Mentale Scuole
            </td>
            <td class="border-bonifica">
              {{ matricolaCorretta.prep_mentale }}
            </td>
            <td class="border-bonifica">{{ matricolaErrata.prep_mentale }}</td>
          </tr>
          <tr>
            <td
              class="fs-6 text-gray-800 border-bonifica"
              style="font-weight: 500 !important"
            >
              Educ. Alimentare Scuole
            </td>
            <td class="border-bonifica">
              {{ matricolaCorretta.educatore_alimentare }}
            </td>
            <td class="border-bonifica">
              {{ matricolaErrata.educatore_alimentare }}
            </td>
          </tr>
          <tr>
            <td
              class="fs-6 text-gray-800 border-bonifica"
              style="font-weight: 500 !important"
            >
              Insegnante Scuole
            </td>
            <td class="border-bonifica">
              {{ matricolaCorretta.insegnante_scuole }}
            </td>
            <td class="border-bonifica">
              {{ matricolaErrata.insegnante_scuole }}
            </td>
          </tr>
          <tr>
            <td
              class="fs-6 text-gray-800 border-bonifica"
              style="font-weight: 500 !important"
            >
              Vivaio
            </td>
            <td class="border-bonifica">
              <div class="card shadow bonifica" v-if="matricolaCorretta.vivaio">
                <table class="table gy-3 gs-7 align-middle">
                  <thead>
                    <tr>
                      <th>Società</th>
                      <th>Stagione</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(value, index) in matricolaCorretta.vivaio"
                      :key="index"
                    >
                      <td>{{ value.denominazione }}</td>
                      <td>{{ value.stagione }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
            <td class="border-bonifica">
              <div class="card shadow bonifica" v-if="matricolaErrata.vivaio">
                <table class="table gy-3 gs-7 align-middle">
                  <thead>
                    <tr>
                      <th>Società</th>
                      <th>Stagione</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(value, index) in matricolaErrata.vivaio"
                      :key="index"
                    >
                      <td>{{ value.denominazione }}</td>
                      <td>{{ value.stagione }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
          <tr>
            <td
              class="fs-6 text-gray-800 border-bonifica"
              style="font-weight: 500 !important"
            >
              Sanzioni
            </td>
            <td class="border-bonifica">
              <div
                class="card shadow bonifica"
                v-if="matricolaCorretta.sanzioni"
              >
                <table class="table gy-3 gs-7 align-middle">
                  <thead>
                    <tr>
                      <th>Anno</th>
                      <th>Numero procedimento</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(value, index) in matricolaCorretta.sanzioni"
                      :key="index"
                    >
                      <td>{{ value.anno }}</td>
                      <td>{{ value.num_proc }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
            <td class="border-bonifica">
              <div class="card shadow bonifica" v-if="matricolaErrata.sanzioni">
                <table class="table gy-3 gs-7 align-middle">
                  <thead>
                    <tr>
                      <th>Anno</th>
                      <th>Numero procedimento</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(value, index) in matricolaErrata.sanzioni"
                      :key="index"
                    >
                      <td>{{ value.anno }}</td>
                      <td>{{ value.num_proc }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
          <tr>
            <td
              class="fs-6 text-gray-800 border-bonifica"
              style="font-weight: 500 !important"
            >
              MAV
            </td>
            <td class="border-bonifica">
              <div class="card shadow bonifica" v-if="matricolaCorretta.mav">
                <table class="table gy-3 gs-7 align-middle">
                  <thead>
                    <tr>
                      <th>Anno</th>
                      <th>Descrizione</th>
                      <th>Approvato</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(value, index) in matricolaCorretta.mav"
                      :key="index"
                    >
                      <td>{{ value.anno }}</td>
                      <td>{{ value.descrizione }}</td>
                      <td v-if="value.approvato">Si</td>
                      <td v-if="!value.approvato">No</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
            <td class="border-bonifica">
              <div class="card shadow bonifica" v-if="matricolaErrata.mav">
                <table class="table gy-3 gs-7 align-middle">
                  <thead>
                    <tr>
                      <th>Anno</th>
                      <th>Descrizione</th>
                      <th>Approvato</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(value, index) in matricolaErrata.mav"
                      :key="index"
                    >
                      <td>{{ value.anno }}</td>
                      <td>{{ value.descrizione }}</td>
                      <td v-if="value.approvato">Si</td>
                      <td v-if="!value.approvato">No</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    matricolaCorretta: {
      type: Array,
    },
    matricolaErrata: {
      type: Array,
    },
  },

  setup() {
    const getFormatDate = (date) => {
      if (date) {
        const year = date.substring(0, 4);
        const month = date.substring(5, 7);
        const day = date.substring(8, date.length);

        return `${day}/${month}/${year}`;
      }
    };

    return {
      getFormatDate,
    };
  },
};
</script>

<style scoped>
.border-bonifica {
  border-right: 2px solid #f0f2f5;
}
.shadow {
  box-shadow: 0 0.1rem 0.6rem 0.1rem rgb(0 0 0 / 5%) !important;
}

.table > thead {
  vertical-align: bottom;
  border-bottom: 1px solid #a1a5b8;
}
</style>
