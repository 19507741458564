<template>
  <div>
    <div class="card shadow">
      <div class="pt-4 pb-4 pe-4 ps-4">
        <div class="row">
          <div class="col-sm-3">
            <input
              type="text"
              class="form-control text-success"
              v-model="matricola_corretta"
              placeholder="Matricola da mantenere"
              @input="$emit('setShowAccorpaBtn', false)"
            />
          </div>
          <div class="col-sm-1 text-center align-self-center">
            <button @click="changeMatricole()">
              <i
                class="bi bi-arrow-left-right text-hover-primary fw-bold fs-4"
              ></i>
            </button>
          </div>
          <div class="col-sm-3">
            <input
              type="text"
              class="form-control text-danger"
              v-model="matricola_errata"
              placeholder="Matricola da cancellare"
              @input="$emit('setShowAccorpaBtn', false)"
            />
          </div>
          <div class="text-end col-md-5">
            <button
              class="badge badge-light-primary"
              @click="
                $emit(
                  'viewDetailMatricole',
                  matricola_corretta,
                  matricola_errata
                )
              "
            >
              MOSTRA DATI
            </button>
            <button
              v-if="showAccorpaBtn"
              class="badge badge-light-warning ms-4"
              @click="bonificaAnagrafica(matricola_corretta, matricola_errata)"
            >
              ACCORPA
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from "vue";
import { alertFailed } from "@/composables/swAlert";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "bonifica-anagrafica",
  components: {},
  props: {
    refresh: {
      type: Boolean,
    },
    showAccorpaBtn: {
      type: Boolean,
    },
    numeroMatricolaCorretta: {
      type: Number,
    },
    numeroMatricolaErrata: {
      type: Number,
    },
    matricolaNonTrovata1: {
      type: Number,
    },
    matricolaNonTrovata2: {
      type: Number,
    },
  },
  emits: ["viewDetailMatricole", "accorpaMatricole", "setShowAccorpaBtn"],
  setup(props, { emit }) {
    const matricola_corretta = ref(props.matricolaCorretta);
    const matricola_errata = ref(props.matricolaErrata);
    const matricola_da_tenere = ref(null);
    const matricola_da_cancellare = ref(null);

    const changeMatricole = () => {
      matricola_da_tenere.value = matricola_errata.value;
      matricola_da_cancellare.value = matricola_corretta.value;
      matricola_errata.value = matricola_da_cancellare.value;
      matricola_corretta.value = matricola_da_tenere.value;
      emit(
        "viewDetailMatricole",
        matricola_corretta.value,
        matricola_errata.value
      );
    };

    /*   if (!matricola_corretta || !matricola_errata) {
        alertFailed(
          "Attenzione! Per procedere è necessario compliare i campi matricola e visualizzarne i dati"
        );
      } else if (
        matricola_corretta != props.numeroMatricolaCorretta ||
        matricola_errata != props.numeroMatricolaErrata
      ) {
        alertFailed(
          "Attenzione! Per procedere è necessario visualizzare i dati delle matricole che si desidera accorpare"
        );
        else if (props.matricolaNonTrovata1 || props.matricolaNonTrovata2) {
        alertFailed(
          "Attenzione! non è possibile accorpare matricole che non restituiscono dati"
        );
      }  if (matricola_corretta == matricola_errata) {
        alertFailed(
          "Attenzione! Non è possibile accorpare la stessa matricola"
        );
      } else { */

    const bonificaAnagrafica = (matricola_corretta, matricola_errata) => {
      Swal.fire({
        title: "Sei Sicuro?",
        html:
          "Matricola da mantenere: " +
          matricola_corretta +
          "<br> Matricola da eliminare: " +
          matricola_errata,
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Bonifica anagrafica",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-success",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          emit("accorpaMatricole", matricola_corretta, matricola_errata);
        }
      });
    };

    watch(
      () => props.refresh,
      () => {
        matricola_corretta.value = null;
        matricola_errata.value = null;
      }
    );

    return {
      matricola_corretta,
      matricola_errata,
      matricola_da_tenere,
      matricola_da_cancellare,
      changeMatricole,
      bonificaAnagrafica,
    };
  },
});
</script>
